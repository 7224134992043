import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../css/Careers.css";
import { useEffect } from "react";

const Careers = () => {
  const today = new Date().toLocaleDateString("ja-JP", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const validThrough = new Date(new Date().setMonth(new Date().getMonth() + 6)).toISOString();

  useEffect(() => {
    // 既存の構造化データスクリプトを削除
    const existingScript = document.querySelector(
      'script[type="application/ld+json"]'
    );
    if (existingScript) {
      existingScript.remove();
    }

    // 新しい構造化データスクリプトを作成
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "JobPosting",
      title: "看板の施工スタッフ",
      description:
        "工場での看板の制作や、現場への取り付け、塗装、溶接など、看板制作に伴う色々な作業をお願いします。職種未経験、ブランクがある方、歓迎いたします。簡単な補助から初めて、一歩ずつ慣れていってもらいます。",
      datePosted: new Date().toISOString(),
      validThrough: validThrough,
      employmentType: "FULL_TIME",
      hiringOrganization: {
        "@type": "Organization",
        name: "株式会社あい広告",
        sameAs: "https://www.aikoukoku.co.jp",
        logo: "https://www.aikoukoku.co.jp/logo512.png",
      },
      jobLocation: {
        "@type": "Place",
        address: {
          "@type": "PostalAddress",
          streetAddress:
            "1-2-25 和田山通, 兵庫区, 神戸市ものづくり工場A棟106号室",
          addressLocality: "神戸市",
          addressRegion: "兵庫県",
          postalCode: "652-0884",
          addressCountry: "JP",
        },
      },
      baseSalary: {
        "@type": "MonetaryAmount",
        currency: "JPY",
        value: {
          "@type": "QuantitativeValue",
          value: 300000,
          unitText: "MONTH",
        },
      },
    });
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [today,validThrough]);

  return (
    <div className="careers-container">
      <main>
        <section>
          <div className="president-message">
            <h1>社長メッセージ</h1>
            <p className="ceo-quote">失敗してもいい。<br />チャレンジしよう。</p>
            <img
              src={require("../assets/img/CEO.jpg")}
              alt="CEO"
              className="ceo-photo"
            />
            <p>
              こんにちは。社長の松本です。
              <br />
              当社のホームページをご覧いただきありがとうございます。私たちは看板制作を中心に、屋内外の広告業界で活動しております。
              <br />
              昔は看板といえば、職人がペンキを使い、1点ものとしてすべて手書きで制作していました。誰でも作れるものではなかったのです。
              <br />
              近年では急速に機械化が進み、プリンターでシートに印刷することで誰でも高品質かつ均一に制作することができるようになりました。
              <br />
              しかし、職人が試行錯誤した技術、看板には、見る人の心に訴えかけるパワーがあります。
              <br />
              私たちは最新技術、流行を取り入れつつも職人としてのプライドを持ちながら日々制作に励んでいます。
              <br />
              これからも目まぐるしく変化する業界の中で、私たちの看板が街の人の心に残るよう、チャレンジし続けます。
              <br /><br />
              代表取締役 松本
            </p>
          </div>
          <h1>求人一覧</h1>
          <p>
            現在も募集しております。ご気楽にお問い合わせください。（{today}
            現在）
          </p>
          <h2>[正社員]看板の施工スタッフ</h2>
          <p>あなたが見たことあるあの看板、私たちが作っています。</p>
          <Carousel
            className="main-slider"
            showThumbs={true}
            showStatus={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            autoPlay={true}
            showArrows={true}
            showIndicators={true}
            interval={3000}
            transitionTime={350}
            stopOnHover={true}
            swipeable={true}
            dynamicHeight={true}
            centerMode={false}
            centerSlidePercentage={50}
            emulateTouch={true}
          >
            <div>
              <img
                src={require("../assets/img/job_photo.jpg")}
                alt="施工済み看板1"
              />
            </div>
            <div>
              <img
                src={require("../assets/img/job_photo2.jpg")}
                alt="施工済み看板2"
              />
            </div>
            <div>
              <img
                src={require("../assets/img/job_photo3.jpg")}
                alt="施工済み看板3"
              />
            </div>
            <div>
              <img
                src={require("../assets/img/job_photo4.jpg")}
                alt="施工済み看板4"
              />
            </div>
            <div>
              <img
                src={require("../assets/img/job_photo5.jpg")}
                alt="施工済み看板5"
              />
            </div>
          </Carousel>
          <p>
            💰月給200,000円～
            <br />
            🚉 JR「兵庫駅」より徒歩15分程度
            <br />
            🕒 8:00~17:00
          </p>
          <h3>お仕事内容</h3>
          <p>
            工場での看板の制作や、現場への取り付け、塗装、溶接など、看板制作に伴う色々な作業をお願いします。
            <br />
            職種未経験、ブランクがある方、歓迎いたします。簡単な補助から初めて、一歩ずつ慣れていってもらいます。
            <br />
            しっかりと丁寧にサポートしますのでご安心ください。
            <br />
            もちろん、経験者には即戦力として活躍していただく場をご用意しております。
          </p>
          <h3>募集要項</h3>
          <table>
            <tbody>
              <tr>
                <td>職種</td>
                <td>[正社員]看板の施工スタッフ</td>
              </tr>
              <tr>
                <td>雇用形態</td>
                <td>正社員</td>
              </tr>
              <tr>
                <td>給与</td>
                <td>月給200,000円～（経験・能力により優遇）</td>
              </tr>
              <tr>
                <td>賞与</td>
                <td>
                  年2回
                  <br />
                  ★10年以上連続（創業から）賞与あり
                </td>
              </tr>
              <tr>
                <td>昇給</td>
                <td>
                  年1回
                  <br />
                  ★10年以上連続（創業から）昇給あり
                </td>
              </tr>
              <tr>
                <td>待遇・福利厚生</td>
                <td>
                  社会保険完備、昇給、賞与、交通費（全額）、資格取得支援あり、作業服貸与
                  <br />
                  ※試用期間3ヶ月中も同待遇
                </td>
              </tr>
              <tr>
                <td>勤務地</td>
                <td>
                  株式会社あい広告　第一工場
                  <br />
                  神戸市兵庫区和田山通1-2-25　神戸市ものづくり工場A棟103号室
                  <br />
                  ※転勤なし
                  <a
                    href="https://maps.google.com/maps?q=34.65898863120405,135.16371318065734&z=16&t=m&hl=ja&region=JP&gl=US&mapclient=embed"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="map-button"
                  >
                    <span>勤務地をMAPで確認</span>
                  </a>
                </td>
              </tr>
              <tr>
                <td>アクセス</td>
                <td>JR「兵庫駅」より徒歩15分程度</td>
              </tr>
              <tr>
                <td>応募資格</td>
                <td>
                  ・学歴不問
                  <br />
                  ・普通自動車免許(MT)
                  <br />
                  ★未経験者、ブランクのある方歓迎！
                  <br />
                  ★現場作業経験者、資格保持者は優遇いたします。
                </td>
              </tr>
              <tr>
                <td>求めている人材</td>
                <td>
                  ・明るく前向きに対応ができる方
                  <br />
                  ・コミュニケーションを取るのが好きな方
                  <br />
                  ・コミュニケーションが苦手だけどコツコツ頑張れる方
                </td>
              </tr>
              <tr>
                <td>勤務時間</td>
                <td>8:00～17:00（休憩1時間）</td>
              </tr>
              <tr>
                <td>休日休暇</td>
                <td>
                  週休2日制（月8日）
                  <br />
                  GW休暇
                  <br />
                  夏季休暇
                  <br />
                  年末年始休暇
                </td>
              </tr>
              <tr>
                <td>保険制度</td>
                <td>
                  健康保険
                  <br />
                  厚生年金
                  <br />
                  雇用保険
                  <br />
                  労災保険
                  <br />
                </td>
              </tr>
              <tr>
                <td>試用期間</td>
                <td>
                  3ヶ月
                  <br />
                  ※労働条件は本採用と同等
                </td>
              </tr>
              <tr>
                <td>採用予定人数</td>
                <td>
                  2名
                  <br />
                  採用者が決まり次第募集を終了させていただく場合がございます。
                  <br />
                  お早めの応募をよろしくお願いいたします。
                </td>
              </tr>
              <tr>
                <td>その他</td>
                <td>
                  バイク通勤OK
                  <br />
                  髪型・髪色自由
                  <br />
                  未経験・初心者OK
                  <br />
                  経験者・有資格者歓迎
                  <br />
                  アットホーム
                </td>
              </tr>
              <tr>
                <td>応募方法</td>
                <td>
                  まずはご気楽にお電話またはメールにてお問い合わせください。
                  <br />
                  その後職務経歴書、履歴書をメールでお送りください。
                  <br />
                  面接日程については追ってご連絡いたします。
                </td>
              </tr>
              <tr>
                <td>問い合わせ先</td>
                <td>
                  078-381-6939
                  <br />
                  saiyou@aikoukoku.co.jp
                  <br />
                  担当：松本
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    </div>
  );
};

export default Careers;
