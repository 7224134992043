import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import RightMenu from "./components/RightMenu";
import Home from "./components/Home";
import Footer from "./components/Footer";
import About from "./components/About";
import Careers from "./components/Careers";
import Contact from "./components/Contact";

function App() {
  const appRef = useRef();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isRightMenuActive, setIsRightMenuActive] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  const toggleRightMenu = () => {
    setIsRightMenuActive(!isRightMenuActive);
  };

  useEffect(() => {
    const headerHeight = document.querySelector("header").offsetHeight;
    const HeaderHeight =
      headerHeight /
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    setHeaderHeight(headerHeight);
    appRef.current.style.paddingTop = `${HeaderHeight}rem`;

    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      if (!mobile) {
        setIsRightMenuActive(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      <div className="App" ref={appRef}>
        <Header
          isMobile={isMobile}
          isActive={isRightMenuActive}
          handleButtonClick={toggleRightMenu}
        />
        <RightMenu
          isActive={isRightMenuActive && isRightMenuActive}
          headerHeight={headerHeight}
        />
        <div className="app-routes">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About isMobile={isMobile}/>} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<Contact isMobile={isMobile}/>} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
