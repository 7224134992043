import React from "react";
import "../css/RightMenu.css";

const RightMenu = (props) => {
  return (
    <div className="right-menu" style={{whiteSpace: 'nowrap', display: props.isActive ? 'block' : 'none' }}>
      <aside className={props.isActive ? 'active' : ''} style={{paddingTop: props.headerHeight}}>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <br />
          <li>
            <a href="/about">事業内容</a>
          </li>
          <br />
          <li>
            <a href="/careers">採用情報</a>
          </li>
          <br />
          <li>
            <a href="/contact">お問い合わせ</a>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default RightMenu;