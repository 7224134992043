import "../css/Contact.css";
import { useEffect } from "react";

const Contact = (props) => {
  // 構造化データを追加する useEffect
  useEffect(() => {
    // 既存の構造化データスクリプトを削除
    const existingScript = document.querySelector(
      'script[type="application/ld+json"]'
    );
    if (existingScript) {
      existingScript.remove();
    }

    // 新しい構造化データスクリプトを作成
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: "株式会社あい広告",
      image: "https://www.aikoukoku.co.jp/logo512.png",
      address: {
        "@type": "PostalAddress",
        streetAddress:
          "1-2-25 和田山通, 兵庫区, 神戸市ものづくり工場A棟106号室",
        addressLocality: "神戸市",
        addressRegion: "兵庫県",
        postalCode: "652-0884",
        addressCountry: "JP",
      },
      telephone: "078-381-6939",
      openingHours: "Mo-Fr 08:30-17:00",
      priceRange: "$$",
    });
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="contact">
      <main>
        <section>
          <h1>お問い合わせ</h1>
          <p>屋内外の広告看板や内装・外装工事の見積もりや、求人への応募など、お気軽にお問い合わせください。</p>
          <h2>連絡先</h2>
          <h3>電話番号</h3>
          <p>　078-381-6939</p>
          <h3>メールアドレス</h3>
          <address>
            {"　"}
            <a href="mailto:contact@aikoukoku.co.jp">contact@aikoukoku.co.jp</a>
          </address>
          <h3>応対可能時間</h3>
          <p>　8:30～17:00</p>
          <h2>所在地</h2>
          <h3>本社</h3>
          <p>
            兵庫県神戸市兵庫区和田山通1-2-25　神戸市ものづくり工場A棟106号室
          </p>
          <div className="responsive-iframe-container">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6563.643255567097!2d135.1563405479195!3d34.65920739173777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60008f96d36db989%3A0xef053b138e60ebd6!2z56We5oi45biC44KC44Gu44Gl44GP44KK5bel5aC0!5e0!3m2!1sja!2sjp!4v1727018702981!5m2!1sja!2sjp"
              style={{
                width: props.isMobile ? "100%" : "60%",
                height: props.isMobile ? "50vw" : "30vw",
                border: 0,
              }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <h3>第一工場</h3>
          <p>
            兵庫県神戸市兵庫区和田山通1-2-25　神戸市ものづくり工場A棟103号室
          </p>
          <h3>第二工場</h3>
          <p>神戸市長田区苅藻町1丁目1-47</p>
          <div className="responsive-iframe-container">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6564.460972227643!2d135.15598457645018!3d34.64888167293818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60008f95a6c7a789%3A0xabc15f58f1d3cf3b!2z77yI5qCq77yJ44GC44GE5bqD5ZGKIOW3peWgtA!5e0!3m2!1sja!2sjp!4v1727195490378!5m2!1sja!2sjp"
              style={{
                width: props.isMobile ? "100%" : "60%",
                height: props.isMobile ? "50vw" : "30vw",
                border: 0,
              }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Contact;
