import React from "react";
import "../css/HamburgerButton.css";

const HamburgerButton = ({ isActive, onClick }) => {
return (
    <div
        id="menu-button"
        className={`hamburger-menu-button ${isActive ? "active" : ""}`}
        onClick={onClick}
    >
        <span></span>
        <span></span>
        <span></span>
    </div>
);
};

export default HamburgerButton;