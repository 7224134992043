import "../css/About.css";
import { useEffect } from "react";

const About = (props) => {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".fade-in");
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          element.classList.add("visible");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // 初期ロード時にも実行

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // 構造化データを追加する useEffect
  useEffect(() => {
    // 既存の構造化データスクリプトを削除
    const existingScript = document.querySelector(
      'script[type="application/ld+json"]'
    );
    if (existingScript) {
      existingScript.remove();
    }

    // 新しい構造化データスクリプトを作成
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "株式会社あい広告",
      url: "https://www.aikoukoku.co.jp",
      logo: "https://www.aikoukoku.co.jp/logo512.png",
      description:
        "企画、デザイン、設計、製作、施工まで一貫してサポート。ブランドのメッセージを効果的に伝える広告看板やディスプレイを提供します。",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "078-381-6939",
        contactType: "General Inquiry",
      },
    });

    // // 新しい構造化データスクリプトを作成
    // const script = document.createElement("script");
    // script.type = "application/ld+json";
    // script.text = JSON.stringify({
    //   "@context": "https://schema.org",
    //   "@type": "Product",
    //   name: "屋内外広告看板・ディスプレイの企画、設計、製作、施工",
    //   // "image": [
    //   //   "https://example.com/photos/1x1/photo.jpg",
    //   //   "https://example.com/photos/4x3/photo.jpg",
    //   //   "https://example.com/photos/16x9/photo.jpg"
    //   // ],
    //   description:
    //     "企画、デザイン、設計、製作、施工まで一貫してサポート。ブランドのメッセージを効果的に伝える広告看板やディスプレイを提供します。",
    //   brand: {
    //     "@type": "Brand",
    //     name: "株式会社あい広告",
    //   },
    //   isSimilarTo: [
    //     {
    //       "@type": "Product",
    //       name: "LED、ネオン等電装工事",
    //       description:
    //         "最新のLED技術やネオンを駆使し、目を引く電装工事を行います。夜間でも鮮やかに輝く広告で、ブランドの存在感を高めます。エネルギー効率の高いソリューションを提供し、環境にも配慮した電装工事を実現します。",
    //     },
    //     {
    //       "@type": "Product",
    //       name: "カッティングシート、インクジェット出力",
    //       description:
    //         "高品質なカッティングシートとインクジェット出力を提供し、視覚的に魅力的な広告を制作します。",
    //     },
    //     {
    //       "@type": "Product",
    //       name: "内装/外装工事",
    //       description:
    //         "店舗やオフィスの内装・外装工事を手掛け、機能的で美しい空間を創り出します。高品質な素材と施工で、長くご利用いただける内装・外装を提供します。",
    //     },
    //   ],
    //   offers: {
    //     "@type": "Offer",
    //     priceCurrency: "JPY",
    //     price: "500000",
    //     url: "https://www.aikoukoku.co.jp/about",
    //     availability: "https://schema.org/InStock",
    //     validFrom: "2023-10-01T12:00:00+09:00"
    //   }
    // });
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="about-container">
      <main>
        <section className="aboutus fade-in">
          <h1>私たちについて</h1>
          <p>
            私たちは、神戸市を拠点とする屋内外広告看板の専門会社です。
            <br />
            お客様のビジネスを最大限に引き立てるため、創造的で効果的な広告ソリューションと内装デザインを提供しています。
            <br />
            最新の技術とデザインを駆使し、視覚的に魅力的でインパクトのある広告や内装を制作します。
          </p>
        </section>
        <section className="mission fade-in">
          <h2>私たちのミッション</h2>
          <p>
            私たちのミッションは、お客様のブランドメッセージを効果的に伝え、長く愛される高品質な施工を提供することです。
            <br />
            地域社会に根ざし、信頼されるパートナーとして、持続可能な広告および内装ソリューションを提供します。
          </p>
        </section>
        <div className="product-container">
          <section className="service1 fade-in">
            <h2>私たちのサービス</h2>
            <div className="product-description">
              <div className="product-text">
                <h3>屋内外広告看板・ディスプレイの企画、設計、製作、施工</h3>
                {props.isMobile && (
                  <div className="product-image">
                    <img
                      src={require("../assets/img/product_photo1.jpg")}
                      alt="product_photo1"
                      className="product_photo1"
                    />
                  </div>
                )}
                <p>
                  デザインから設計、製作、施工まで一貫したサービスを提供します。
                  <br />
                  お客様の思いを形にし、ブランドのメッセージを効果的に伝える広告看板やディスプレイを提供します。
                </p>
              </div>
              {!props.isMobile && (
                <div className="product-image">
                  <img
                    src={require("../assets/img/product_photo1.jpg")}
                    alt="product_photo1"
                    className="product_photo1"
                  />
                </div>
              )}
            </div>
          </section>
          <section className="service2 fade-in">
            <div className="product-description">
              {!props.isMobile && (
                <div className="product-image">
                  <img
                    src={require("../assets/img/product_photo2.jpg")}
                    alt="product_photo2"
                    className="product_photo2"
                  />
                </div>
              )}
              <div className="product-text">
                <h3>LED、ネオン等電装工事</h3>
                {props.isMobile && (
                  <div className="product-image">
                    <img
                      src={require("../assets/img/product_photo2.jpg")}
                      alt="product_photo2"
                      className="product_photo2"
                    />
                  </div>
                )}
                <p>
                  最新のLED技術やネオンを駆使し、夜間でも鮮やかに輝く広告を提供し、ブランドの存在感を高めます。
                  <br />
                  エネルギー効率の高いソリューションを提供し、環境にも配慮します。
                </p>
              </div>
            </div>
          </section>
          <section className="service3 fade-in">
            <div className="product-description">
              <div className="product-text">
                <h3>カッティングシート、インクジェット出力</h3>
                {props.isMobile && (
                  <div className="product-image">
                    <img
                      src={require("../assets/img/product_photo3.jpg")}
                      alt="product_photo3"
                      className="product_photo3"
                    />
                  </div>
                )}
                <p>
                  店舗のウィンドウディスプレイや車両ラッピングなど、複雑な形状にも対応可能です。
                  <br />
                  高品質のカッティングシートやインクジェット出力を用いて、長期間にわたり美しい状態を保ちます。
                </p>
              </div>
              {!props.isMobile && (
                <div className="product-image">
                  <img
                    src={require("../assets/img/product_photo3.jpg")}
                    alt="product_photo3"
                    className="product_photo3"
                  />
                </div>
              )}
            </div>
          </section>
          <section className="service4 fade-in">
            <div className="product-description">
              {!props.isMobile && (
                <div className="product-image">
                  <img
                    src={require("../assets/img/product_photo4.jpg")}
                    alt="product_photo4"
                    className="product_photo4"
                  />
                </div>
              )}
              <div className="product-text">
                <h3>内装/外装工事</h3>
                {props.isMobile && (
                  <div className="product-image">
                    <img
                      src={require("../assets/img/product_photo4.jpg")}
                      alt="product_photo4"
                      className="product_photo4"
                    />
                  </div>
                )}
                <p>
                  店舗やオフィスの内装・外装工事を手掛け、機能的で美しい空間を創り出します。
                  <br />
                  お客様のニーズに合わせ、立体的なデザインで空間を演出します。
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default About;
