import React, { useEffect } from 'react';
import '../css/Footer.css';

const Footer = () => {
  useEffect(() => {
    const handleScrollAndResize = () => {
      const footer = document.querySelector('.custom-footer');
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // スクロール位置がページの最下部の95%以上に達したときにフッターを表示
      if (scrollPosition + windowHeight >= documentHeight * 0.99) {
        footer.style.display = 'flex';
      } else {
        footer.style.display = 'none';
      }
    };

    // 初回レンダリング時にフッターの表示状態を確認
    handleScrollAndResize();

    window.addEventListener('scroll', handleScrollAndResize);
    window.addEventListener('resize', handleScrollAndResize);

    // コンポーネントのクリーンアップ時にイベントリスナーを削除
    return () => {
      window.removeEventListener('scroll', handleScrollAndResize);
      window.removeEventListener('resize', handleScrollAndResize);
    };
  }, []);

  return (
    <div className="custom-footer">
      <footer>
        <p>&copy; 株式会社あい広告. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Footer;