import "../css/Home.css";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".fade-in");
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          element.classList.add("visible");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // 初期ロード時にも実行

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // 構造化データを追加する useEffect
  useEffect(() => {
    // 既存の構造化データスクリプトを削除
    const existingScript = document.querySelector(
      'script[type="application/ld+json"]'
    );
    if (existingScript) {
      existingScript.remove();
    }

    // 新しい構造化データスクリプトを作成
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "株式会社あい広告",
      url: "https://www.aikoukoku.co.jp",
      logo: "https://www.aikoukoku.co.jp/logo512.png",
      description:
        "看板や内装/外装工事を企画、デザインから製作、施工まで一貫して対応。高品質なサービスでお客様のニーズに応えます。",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "078-381-6939",
        contactType: "General Inquiry",
      },
    });
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="home">
      <main>
        <section>
          <section className="intro fade-in">
            <h1>あなたのビジョンを形にするパートナー</h1>
            <p>
              私たちは、神戸市を拠点とする広告および内装の専門会社です。
              <br />
              看板製作の企画から設計、施工まで一貫して行い、お客様のビジネスを最大限に引き立てるため、創造的で効果的な広告ソリューションと内装デザインを提供しています。
            </p>
          </section>
          <section className="strengths fade-in">
            <h2>私たちの強み</h2>
            <h3>高品質な施工</h3>
            <p>長くご利用いただける高品質な広告と内装を提供します</p>
            <h3>最新技術の活用</h3>
            <p>
              LEDやネオンなどの最新技術を駆使し、目を引くデザインを実現します
            </p>
            <h3>一貫したサポート</h3>
            <p>
              企画から施工まで一貫してサポートし、お客様のビジョンを形にします
            </p>
          </section>
          <section className="career fade-in">
            <h2>
              看板業界で新しいキャリアをスタートしませんか？未経験者歓迎！
            </h2>
            <p>
              私たちは、若い力を求めています！
              <br />
              一から技術を身につけられる環境で、力仕事が苦ではなく、明るく前向きに対応できる方を歓迎します。
              <br />
              コミュニケーションが得意な方はもちろん、寡黙だけどコツコツ頑張る方も大歓迎です。
              <br />
              看板のデザインから製作まで、幅広いスキルを身につけるチャンスがあります。最初は簡単な補助から初めて、一歩ずつ慣れていってもらいます。
              <br />
              しっかりと丁寧にサポートしますのでご安心ください。もちろん、経験者には即戦力として活躍していただく場をご用意しております。
              <br />
              後世に残る看板を一緒に作りませんか？
            </p>
          </section>
          <section className="company-info fade-in">
            <table>
              <tbody>
                <tr>
                  <td>会社名</td>
                  <td>株式会社あい広告</td>
                </tr>
                <tr>
                  <td>設立</td>
                  <td>平成23年 12月11日</td>
                </tr>
                <tr>
                  <td>代表取締役</td>
                  <td>松本 巌</td>
                </tr>
                <tr>
                  <td>所在地</td>
                  <td>
                    事務所：神戸市兵庫区和田山通1丁目2-25
                    神戸市ものづくり工場A棟106
                    <br />
                    第一工場：神戸市兵庫区和田山通1丁目2-25
                    神戸市ものづくり工場A棟103
                    <br />
                    第二工場：神戸市長田区苅藻町1丁目1-47
                    <a
                      href="https://maps.google.com/maps?q=34.65898863120405,135.16371318065734&z=16&t=m&hl=ja&region=JP&gl=US&mapclient=embed"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="map-button"
                    >
                      <span>事務所をMAPで確認</span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>事業内容</td>
                  <td>
                    屋内外広告看板、ディスプレイの企画、設計、製作、施工
                    <br />
                    LED、ネオン等電装工事
                    <br />
                    カッティングシート、インクジェット出力
                    <br />
                    内装/外装工事
                    <br />
                    広告物申請
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>資格等</td>
                  <td>
                    建築全般 第116231号
                    <br />
                    第二種電気工事士
                    <br />
                    屋外広告士
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </section>
      </main>
    </div>
  );
};

export default Home;
