import "../css/Header.css";
import HamburgerButton from "./HamburgerButton";

const Header = (props) => {
  return (
    <header className="custom-header">
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={process.env.PUBLIC_URL + "/logo512.png"}
          alt="Logo"
          style={{ width: "3rem", margin: "0.5rem" }}
        />
        <h1>株式会社あい広告</h1>
      </div>
      {props.isMobile ? (
        <>
          <nav>
            <HamburgerButton
              isActive={props.isActive}
              onClick={props.handleButtonClick}
            />
          </nav>
        </>
      ) : (
        <>
          <nav>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">事業内容</a>
              </li>
              <li>
                <a href="/careers">採用情報</a>
              </li>
              <li>
                <a href="/contact">お問い合わせ</a>
              </li>
            </ul>
          </nav>
        </>
      )}
    </header>
  );
};

export default Header;
